<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataForm.record"
            :search="search"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="4">
                  <div class="detail-desc">
                    <div class="row-info-space">
                      <p v-if="dataForm?.site" class="project-address">{{ dataForm.site.global }} / {{ dataForm.site.regional }} / {{ dataForm.site.area }} / {{ dataForm.site.project }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>SITE ID</p>
                      <p>{{ dataForm?.site ? dataForm.site.id : '' }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>SITE NAME</p>
                      <p>{{ dataForm?.site ? dataForm.site.site_name : '' }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>COMMISSIONED DATE</p>
                      <p>{{ dataForm?.site?.commissioned_date ? MOMENT(dataForm.site.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                    </div>
                  </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="5" class="w-50">
                  <div class="total-heading">
                    <h1>Total Device: {{ dataForm?.total ? formatCommasAndDecimalForNumber(dataForm.total) : 0 }}</h1>
                  </div>
                  <div class="switch-component switch-custom">
                    <button class="dialog-default-button data-table-add-size dialog-bg-grey-button switch-btn add-user-btn button-hover" @click="openDialogAddAsset">
                      Add New Device
                    </button>
                    <v-btn
                      class="switch-btn map-switch button-hover mr-4"
                      icon
                      title="Maintenance Schedule"
                      @click="openDialogMaintenanceSchedule"
                    >
                      <img src="@/assets/tables/setting-icon.svg" />
                    </v-btn>
                    <v-text-field
                      v-model="search"
                      placeholder="Search..."
                      class="search-site"
                      prepend-inner-icon="fas fa-search"
                      rounded
                      outlined
                      filled
                    ></v-text-field>
                    <v-btn
                      class="switch-btn button-hover ml-4"
                      icon
                      @click="openDialogFilterAsset"
                      title="Filter Device"
                    >
                      <img src="@/assets/tables/filter-icon.svg" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.sn`]="{ item }">
              <div class="d-flex justify-content-center" style="min-width: 135px">
                <span>{{ item.sn }}</span>
              </div>
            </template>
            <template v-slot:[`item.original_sn`]="{ item }">
              <div class="d-flex justify-content-center">
                <span>{{ item.original_sn ? item.original_sn : '−−' }}</span>
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td 
                class="text-center" 
                :style="
                  `background-color: ${STATUS_BACKGROUND_COLORS[item.status]};
                   color: ${item.status === 'offline' ? '#636366' : '#ffffff'}!important;
                  `
                ">
                  {{ capitalizeFirstLetter(item.status) }}
                </td>
            </template>
            <template v-slot:[`item.software_version`]="{ item }">
              <span>{{ item.software_version ? item.software_version : '✕' }}</span>
            </template>
            <template v-slot:[`item.device_key`]="{ item }">
              <div v-if="item.device_key" class="d-flex">
                <span 
                  @click="copyDeviceKey(item.device_key)" 
                  class="cursor-pointer mr-3 icon-action" 
                  title="Click to copy"
                >
                  <i class="far fa-copy"></i>
                </span>
                <span>{{ showDeviceKey(item.device_key) }}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-content-center">
                <template v-if="!item.deleted_at">
                  <v-btn
                    :ripple="false"
                    icon
                    title="Edit Device"
                    :disabled="notAllowedActions()"
                    @click="openDialogEdit(item)"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_edit.svg" />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    title="Deregister Device"
                    :disabled="notAllowedActions()"
                    @click="openConfirmDialog(item, deleteAsset, 'Deregister Device', 'You are about to deregister a device from the current system. The device will no longer be registered and controlled.')"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_delete.svg" 
                    />
                  </v-btn>
                </template>
              </div>
            </template>
          </v-data-table>
          <div class="data-table-paging">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" class="general-diagram-content">
          <div class="diagram-content-wrapper">
            <div class="diagram-content">
              <div class="diagram-icon">
                <v-btn icon :title="`${collapseDiagram ? 'Close Diagram' : 'Open Diagram'}`">
                  <img class="light-hover" src="@/assets/tables/diagram-icon.svg" v-b-toggle="'collapse-diagram'" />
                </v-btn>
              </div>
              <b-collapse id="collapse-diagram" v-model="collapseDiagram">
                <div class="diagram-main">
                  <div class="row diagram-main-row text-light">
                    <div class="col-3 position-relative">
                      <hr class="position-absolute m-0" color="#fff" style="top: 50%; right: -10%; transform: translateY(-50%); width: 80%;">
                      <div :class="`${dataDiagram?.pm ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-small" style="position: relative;  z-index: 1;" @click="openDialogDeviceDetail(dataDiagram?.pm)">
                        <div :class="`${dataDiagram?.pm ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.pm?.type ? typeName[dataDiagram.pm.type] : ''}}</p>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.pm?.status]}`" class="diagram-item-status">{{ dataDiagram?.pm?.status ? capitalizeFirstLetter(dataDiagram.pm.status) : ''}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 position-relative">
                      <hr class="position-absolute m-0" color="#fff" style="top: 50%; right: -10%; transform: translateY(-50%); width: 80%">
                      <div :class="`${dataDiagram?.sc ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-small" style="position: relative;  z-index: 1;" @click="openDialogDeviceDetail(dataDiagram?.sc)">
                        <div :class="`${dataDiagram?.sc ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.sc?.type ? typeName[dataDiagram.sc.type] : ''}}</p>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.sc?.status]}`" class="diagram-item-status">{{ dataDiagram?.sc?.status ? capitalizeFirstLetter(dataDiagram.sc.status) : ''}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 ">
                      <div :class="`${dataDiagram?.evse ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large position-relative" @click="openDialogDeviceDetail(dataDiagram?.evse)">
                        <hr class="position-absolute m-0 horizontal-line" color="#fff">
                        <hr class="position-absolute m-0 vertical-line" color="#868687" style="bottom: 0; transform: translateY(58%); width: 1px; height: 117%;">
                        <div :class="`${dataDiagram?.evse ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.evse?.type ? typeName[dataDiagram.evse.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.evse?.rated_capacity ? dataDiagram.evse.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.evse?.status]}`" class="diagram-item-status">{{ dataDiagram?.evse?.status ? capitalizeFirstLetter(dataDiagram.evse.status) : ''}}</span>
                        </div>
                      </div>
                      <div :class="`${dataDiagram?.bess ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large" @click="openDialogDeviceDetail(dataDiagram?.bess)">
                        <div :class="`${dataDiagram?.bess ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.bess?.type ? typeName[dataDiagram.bess.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.bess?.rated_capacity ? dataDiagram.bess.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.bess?.status]}`" class="diagram-item-status">{{ dataDiagram?.bess?.status ? capitalizeFirstLetter(dataDiagram.bess.status) : ''}}</span>
                        </div>
                      </div>
                      <div :class="`${dataDiagram?.pv ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large position-relative" @click="openDialogDeviceDetail(dataDiagram?.pv)">
                        <hr class="position-absolute m-0 horizontal-line" color="#fff" >
                        <hr class="position-absolute m-0 vertical-line" color="#868687" style="top: 0; transform: translateY(-58%); width: 1px; height: 117%;">
                        <div :class="`${dataDiagram?.pv ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.pv?.type ? typeName[dataDiagram.pv.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.pv?.rated_capacity ? dataDiagram.pv.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.pv?.status]}`" class="diagram-item-status">{{ dataDiagram?.pv?.status ? capitalizeFirstLetter(dataDiagram.pv.status) : ''}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div :class="`${dataDiagram?.evse_2 ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large" @click="openDialogDeviceDetail(dataDiagram?.evse_2)">
                        <div :class="`${dataDiagram?.evse_2 ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.evse_2?.type ? typeName[dataDiagram.evse_2.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.evse_2?.rated_capacity ? dataDiagram.evse_2.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.evse_2?.status]}`" class="diagram-item-status">{{ dataDiagram?.evse_2?.status ? capitalizeFirstLetter(dataDiagram.evse_2.status) : ''}}</span>
                        </div>
                      </div>
                      <div :class="`${dataDiagram?.bess_2 ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large" @click="openDialogDeviceDetail(dataDiagram?.bess_2)">
                        <div :class="`${dataDiagram?.bess_2 ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.bess_2?.type ? typeName[dataDiagram.bess_2.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.bess_2?.rated_capacity ? dataDiagram.bess_2.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.bess_2?.status]}`" class="diagram-item-status">{{ dataDiagram?.bess_2?.status ? capitalizeFirstLetter(dataDiagram.bess_2.status) : ''}}</span>
                        </div>
                      </div>
                      <div :class="`${dataDiagram?.pv_2 ? 'diagram-item-box' : 'diagram-item-box-empty'}`" class="diagram-item-box-large" @click="openDialogDeviceDetail(dataDiagram?.pv_2)">
                        <div :class="`${dataDiagram?.pv_2 ? '' : 'visibility-hidden'}`" class="diagram-item">
                          <p class="diagram-item-name">{{ dataDiagram?.pv_2?.type ? typeName[dataDiagram.pv_2.type] : ''}}</p>
                          <span class="diagram-item-capacity">{{ dataDiagram?.pv_2?.rated_capacity ? dataDiagram.pv_2.rated_capacity : ''}}</span>
                          <span :style="`color: ${STATUS_COLORS[dataDiagram?.pv_2?.status]}`" class="diagram-item-status">{{ dataDiagram?.pv_2?.status ? capitalizeFirstLetter(dataDiagram.pv_2.status) : ''}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddAsset"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="756"
      >
        <v-card>
          <v-card-title>Add New Device</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device SN</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      style="margin-right: 8px;"
                      v-model="deviceSn"
                      :rules="deviceSnRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Device SN"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <button
                      class="dialog-default-button dialog-bg-grey-button retrieve-info-button"
                      style="max-height: 48px;"
                      type="button"
                      :ripple="false"
                      @click="retrieveInfo(deviceSn)"
                    >
                      Retrieve Info
                    </button>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Original SN</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="originalSn"
                      required
                      autocomplete="off"
                      placeholder="Enter Original SN"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2" style="pointer-events: none;">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device Type</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="deviceTypeList"
                      v-model="deviceType"
                      :rules="deviceTypeRules"
                      item-text="name"
                      item-value="key"
                      :class="`${deviceType ? 'custom-hidden-input' : ''}`"
                      required
                      filled
                      :menu-props="{ contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Device Type"
                      :validate-on-blur="true"
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device Name</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="deviceName"
                      :rules="deviceNameRules"
                      required
                      autocomplete="off"
                      placeholder="Device Name"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Brand</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="deviceBrand"
                      :rules="deviceBrandRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Brand"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Model</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="deviceModel"
                      :rules="deviceModelRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Model"
                      filled
                      :validate-on-blur="true"
                      @input="handleInputModel(deviceModel)"
                      @blur="closeAutocomplete"
                      @keydown.down="navigateOptions(e, 'add')"
                      @keydown.up="navigateOptions(e, 'add')"
                      @keyup.enter="selectHighlightedOption('add')"
                    ></v-text-field>
                    <template v-if="showAutocomplete">
                      <div class="autocomplete-items">
                        <div 
                          class="model-item" 
                          v-for="(option, index) in filteredOptions" 
                          :key="index" 
                          @click="selectModelOption(option, 'add')"
                          :class="{ 'highlighted': index === highlightedIndex }"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </template>
                  </v-col>
              </v-row>
                <v-row no-gutters class="pt-2" v-if="!['sc', 'pm'].includes(deviceType) && (deviceType !== '')">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Rated Value</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="ratedCapacity"
                      :rules="ratedCapacityRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Rated Value"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddAsset"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogMaintenanceSchedule"
        persistent
        content-class="custom-content-modals"
        max-width="756"
      >
        <v-card>
          <v-card-title>Maintenance Schedule</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="maintenanceScheduleForm"
              @submit.prevent="submitMaintenanceSchedule"
              autocomplete="off"
            >
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="4" class="modal-label">
                  <span>Next Maintenance</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-top="20"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="custom_schedule_full_width"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="nextMaintenanceDateText"
                        :rules="nextMaintenanceRules"
                        append-icon="fas fa-caret-down"
                        placeholder="Select Next Maintenance"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="date-picker-text-field date-picker-batch"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="nextMaintenanceDate"
                      no-title
                      :min="NOW_DATE().format(DATE_PICKER_FORMAT)"
                      :dark="true"
                      class="customize_commissioned_date_picker"
                      @change="changedDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="4" class="modal-label">
                  <span>Assign Installer</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    :items="assignInstallersList"
                    v-model="assignInstaller"
                    :rules="assignInstallerRules"
                    item-text="username"
                    item-value="id"
                    :class="`${assignInstaller.length ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections disabled_input"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Installer"
                    multiple
                    chips
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2">
                        <span>{{ item.username }}</span>
                      </v-chip>
                      <span
                        v-if="index === 2"
                        class="text-grey text-caption align-self-center"
                      >
                        (+{{ assignInstaller.length - 2 }} others)
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item class="customize_v-list-item">
                        <v-list-item-content>
                          <v-text-field v-model="searchTerm" placeholder="Search Installer" @input="searchInstallers"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogMaintenanceSchedule"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditAsset"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="756"
      >
        <v-card>
          <v-card-title>Edit Device</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-row no-gutters class="mb-4">
              <v-col cols="6">
                <v-btn
                  style="height: 50px;" 
                  block 
                  outlined 
                  color="primary"
                  class="dialog-bg-outline-button dialog-default-button" 
                  :class="{'dialog-bg-grey-button': activeSection === 'info'}"
                  @click="activeSection = 'info'"
                >
                  Device Information
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn 
                  style="height: 50px;" 
                  block 
                  outlined 
                  color="primary"
                  class="dialog-bg-outline-button dialog-default-button"  
                  :class="{'dialog-bg-grey-button': activeSection === 'settings'}"
                  @click="activeSection = 'settings'"
                >
                  Device Setting
                </v-btn>
              </v-col>
          </v-row>
            <v-form
              ref="editForm"
              @submit.prevent="submitEdit"
              autocomplete="off"
            >
            <div v-if="activeSection === 'info'" class="scroll_dialog_form" style="height: 500px;">
              <v-row no-gutters class="pt-2" style="pointer-events: none;">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device SN</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      style="margin-right: 8px;"
                      v-model="editDeviceSn"
                      :rules="deviceSnRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Device SN"
                      filled
                      :validate-on-blur="true"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <button
                      class="dialog-default-button dialog-bg-grey-button retrieve-info-button"
                      style="max-height: 48px;"
                      type="button"
                      :ripple="false"
                      @click="retrieveInfo"
                      disabled
                    >
                      Retrieve Info
                    </button>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Original SN</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editOriginalSn"
                      required
                      autocomplete="off"
                      placeholder="Enter Original SN"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2" style="pointer-events: none;">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device Type</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="deviceTypeList"
                      v-model="editDeviceType"
                      :rules="deviceTypeRules"
                      item-text="name"
                      item-value="key"
                      :class="`${editDeviceType ? 'custom-hidden-input' : ''}`"
                      required
                      filled
                      :menu-props="{ contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Device Type"
                      :validate-on-blur="true"
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device Name</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editDeviceName"
                      :rules="deviceNameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Device Name"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Brand</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editDeviceBrand"
                      :rules="deviceBrandRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Brand"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Model</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editDeviceModel"
                      :rules="deviceModelRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Model"
                      filled
                      :validate-on-blur="true"
                      @input="handleInputModel(editDeviceModel)"
                      @blur="closeAutocomplete"
                      @keydown.down="navigateOptions(e, 'edit')"
                      @keydown.up="navigateOptions(e, 'edit')"
                      @keyup.enter="selectHighlightedOption('edit')"
                    ></v-text-field>
                    <template v-if="showAutocomplete">
                      <div class="autocomplete-items">
                        <div 
                          class="model-item" 
                          v-for="(option, index) in filteredOptionsEdit" 
                          :key="index" 
                          @click="selectModelOption(option, 'edit')"
                          :class="{ 'highlighted': index === highlightedIndex }"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2" v-if="!['sc', 'pm'].includes(editDeviceType) && (editDeviceType !== '')">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Rated Value</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editRatedCapacity"
                      :rules="ratedCapacityRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Rated Value"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-else class="scroll_dialog_form" style="height: 500px;">
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device</span>
                  </v-col>
                  <v-col cols="12" md="9" class="pt-3">
                    <span >{{deviceNameText}}</span>
                  </v-col>
                </v-row>

                <v-row v-if="['pv'].includes(editDeviceType) && (editDeviceType !== '')" no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Lower PV Input Voltage Limit</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="editLowerInputVoltageLimit"
                      :rules="lowerInputVoltageLimitRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Lower PV Input Voltage Limit"
                      filled
                      :validate-on-blur="true"
                    >
                      <template v-slot:append-outer>
                        <span>V</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogEditAsset"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFilterAsset"
        persistent
        content-class="custom-content-modals"
        max-width="700"
      >
        <v-card>
          <v-card-title class="v-card__title-filter">Filter</v-card-title>
          <v-card-text class="custom-asset-card v-card__text-filter">
            <v-form
              ref="filterAssetForm"
              @submit.prevent="submitFilter()"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Device Type</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-sc" name="type-sc" value="sc" />
                        <label for="type-sc">eBox</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-pm" name="type-pm" value="pm" />
                        <label for="type-pm">Smart Meter</label>
                      </div>
                    </v-col>

                    <v-col cols="4" style="visibility: hidden;">
                      <div class="option-item">
                        <input class="cursor-pointer" type="checkbox" id="type-hidden" />
                        <label for="type-hidden">Hidden</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-pv" name="type-pv" value="pv" />
                        <label for="type-pv">PV Inverter</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-bess" name="type-bess" value="bess" />
                        <label for="type-bess">BESS</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-evse" name="type-evse" value="evse" />
                        <label for="type-evse">EVSE</label>
                      </div>
                    </v-col>          
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Device Status</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceStatuses" class="cursor-pointer" type="checkbox" id="normal-status" name="normal-status" value="normal" />
                        <label for="normal-status">Normal</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceStatuses" class="cursor-pointer" type="checkbox" id="alarm-status" name="alarm-status" value="alarm" />
                        <label for="alarm-status">Alarm</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceStatuses" class="cursor-pointer" type="checkbox" id="offline-status" name="offline-status" value="offline" />
                        <label for="offline-status">Offline</label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Commissioned Date</p>
                  <v-row no-gutters class="checkboxes-row commissioned-date-row">
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p>From</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-menu
                        ref="commissionedDateMenu"
                        v-model="commissionedDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="332"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="commissionedDateTextFrom"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="fas fa-caret-down"
                            class="date-picker-text-field"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          flat
                          v-model="commissionedDateRange"
                          no-title
                          range
                          width="auto"
                          first-day-of-week="0"
                          type="date"
                          :dark="true"
                          class="customize_commissioned_date_picker"
                          @input="closeCommissionedDateMenu"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p style="margin-left: 13px;">To</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-text-field
                        v-model="commissionedDateTextTo"
                        readonly
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        @click="openCommissionedDateMenu"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters class="filter-buttons-row">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogFilterAsset"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="resetDialogFilterAsset"
                  >
                    RESET
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    APPLY
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(assetItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
      <DeviceDetail
        v-if="is_device_detail"
        :dataDevice="data_device" 
        :deviceDetailDialog="is_device_detail"
        @closeDialog="closeDialogDeviceDetail"
      />
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { assetService, siteService } from "@/services";
import moment from "moment";
import DeviceDetail from "@/components/DeviceDetail.vue";
export default {
  components: { DeviceDetail },
  data() {
    return {
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "ID", value: "id", align: 'center' },
        { text: "Device Type", value: "type", align: 'center' },
        { text: "Device Name", value: "name", align: 'center', sortable: false },
        { text: "Device SN", value: "sn", align: 'center', sortable: false },
        { text: "Original SN", value: "original_sn", align: 'center', sortable: false },
        { text: "Brand", value: "brand", align: 'center' },
        { text: "HW Model", value: "model", align: 'center', sortable: false },
        { text: "SW Version", value: "software_version", align: 'center', sortable: false },
        { text: "Device Key", value: "device_key", align: 'center', sortable: false },
        { text: "Commissioned Date", value: "date_of_commission", align: 'center' },
        { text: "Last Maintenance", value: "last_maintenance", align: 'center' },
        { text: "Next Maintenance", value: "next_maintenance", align: 'center' },
        { text: "Device Status", value: "status", width: 145, align: "center" },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dialogAddAsset: false,
      dialogFilterAsset: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      assetItem: {},
      dataForm: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      deviceName: "",
      deviceSn: "",
      deviceType: "",
      deviceBrand: "",
      deviceModel: "",
      ratedCapacity: "",
      deviceTypeList: [
        {
          key: "sc",
          name: "eBox",
        },
        {
          key: "pv",
          name: "PV Inverter",
        },
        {
          key: "bess",
          name: "BESS",
        },
        {
          key: "evse",
          name: "EVSE",
        },
        {
          key: "pm",
          name: "Smart Meter",
        }
      ],
      commissionedDateMenu: false,
      selectedDeviceTypes: [],
      selectedDeviceStatuses: [],
      commissionedDateRange: [],
      dialogEditAsset: false,
      editDeviceName: "",
      editDeviceSn: "",
      editOriginalSn: "",
      originalSn: "",
      editDeviceType: "",
      editDeviceBrand: "",
      editDeviceModel: "",
      editRatedCapacity: "",
      editLowerInputVoltageLimit: "",
      collapseDiagram: true,
      deviceNameRules: [
        (v) => !!v || "Device Name is required",
      ],
      deviceTypeRules: [
        (v) =>!!v || "Device Type is required",
      ],
      deviceBrandRules: [
        (v) =>!!v || "Brand is required",
      ],
      deviceModelRules: [
        (v) =>!!v || "Model is required",
      ],
      deviceSnRules: [
        (v) =>!!v || "Device SN is required",
        this.isValidDeviceSn,
      ],
      ratedCapacityRules: [
        (v) =>!!v || "Rated Value is required",
        (v) => !isNaN(v) || "Rated Value must be a number",
        (v) => v >= 0 || "Rated Value must be at least 0",
        (v) => v <= 1000000 || "Rated Value must not exceed 1,000,000",
        (v) => /^\d+(\.\d{1,2})?$/.test(v) || "Rated Value must have at most 2 decimals"
      ],
      nextMaintenanceRules: [
        (v) =>!!v || "Next Maintenance is required",
      ],
      assignInstallerRules: [
        (v) =>!!v.length || "Assign Installer is required",
      ],
      lowerInputVoltageLimitRules: [
        (v) =>!!v || "Input limit is required",
        (v) => !isNaN(v) || "Input limit must be a number",
        (v) => v > 0 && v <= 1000 || "Input limit must be a positive number from 1 to 1000"
      ],
      dataDiagram: {},
      dataAssetsDiagram: [],
      isReloadDiagram: true,
      dialogMaintenanceSchedule: false,
      nextMaintenanceDateText: "",
      nextMaintenanceDate: "",
      assignInstallersList: [],
      assignInstaller: [],
      searchTerm: "",
      installersListCopy: [],
      is_device_detail: false,
      data_device: null,
      modelOptions: ['eBox', 'AT50-11000TM', 'AT50-5048PRO', 'AL32S', 'AL16T', 'SDM239', 'SDM630', 'PM-48100W', 'PM-48200W'],
      showAutocomplete: false,
      highlightedIndex: -1,
      typeName: {
        'pv': "PV Inverter",
        'sc': "eBox",
        'bess': "BESS",
        'evse': "EVSE",
        'pm': "Smart Meter"
      },
      socketDevice: null,
      activeSection: 'info',
      deviceNameText: "",
    };
  },
  async created() {
    await this.getListAssets();
    await this.getListAssignInstallers();
    if (this.assignInstallersList?.length) {
      this.installersListCopy = [...this.assignInstallersList];
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.user;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
    commissionedDateTextFrom () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 0){
        return `${this.commissionedDateRange[0] ? moment(this.commissionedDateRange[0]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    commissionedDateTextTo () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 1){
        return `${this.commissionedDateRange[1] ? moment(this.commissionedDateRange[1]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    filteredOptions() {
      if(this.deviceModel) {
        return this.modelOptions.filter(option =>
          option.toLowerCase().includes(this.deviceModel.toLowerCase())
        );
      }
      return [];
    },
    filteredOptionsEdit() {
      if(this.editDeviceModel) {
        return this.modelOptions.filter(option =>
          option.toLowerCase().includes(this.editDeviceModel.toLowerCase())
        );
      }
      return [];
    },
  },
  mounted() {
    const url = `${this.WEB_SOCKET_URL}?key=${this.X_API_KEY}&type=web`;
    this.socketDevice = new WebSocket(url);
    this.socketDevice.onmessage = async (event) => {
      const socketEventData = JSON.parse(event.data);
      if(socketEventData.action === "fe_the_site_device_status_has_been_changed" && socketEventData.data.site_id == this.$store.state.siteId) {
        await this.submitFilter(true);
      }
    };
  },
  methods: {
    getDataDiagram() {
      const assetCounts = {};
      const formattedArray = this.dataAssetsDiagram.reduce((acc, item) => {
        const type = item.type;
        const inventoryStatus = item.inventory_status;
        if (inventoryStatus !== "de-registered") {
          assetCounts[type] = (assetCounts[type] || 0) + 1;
          let ratedCapacity = item.rated_capacity;
          if (type === "bess" && ratedCapacity !== null) {
            ratedCapacity += " kWh";
          } else if (["evse", "pv"].includes(type) && ratedCapacity !== null) {
            ratedCapacity += " kW";
          }
          const key = this.generateKeyWithTypeAndIndex(type, assetCounts[type]);
          acc[key] = { ...item, rated_capacity: ratedCapacity };
        }
        return acc;
      }, {});
      return formattedArray;
    },
    generateKeyWithTypeAndIndex(type, index, suffix = '_') {
      return index > 1 ? `${type}${suffix}${index}` : type;
    },
    setAssetRetrieveInfo(data) {
      if (data) {
        this.originalSn = data.originalSn || "";
        this.deviceName = data.name || "";
        this.deviceType = data.type || "";
        this.deviceBrand = data.brand || "";
        this.deviceModel = data.model || "";
        this.ratedCapacity = data.rated_capacity || "";
      } else {
        this.originalSn = "";
        this.deviceName = "";
        this.deviceType = "";
        this.deviceBrand = "";
        this.deviceModel = "";
        this.ratedCapacity = "";
      }
    },
    retrieveInfo(sn) {
      if (sn) {
        assetService
          .retrieveInfo(sn)
          .then((res) => {
            if (res.status == 200 && res?.data?.data) {
              this.setAssetRetrieveInfo(res.data.data);
            } else {
              let message = "";
              if (res?.response?.data?.message) {
                message = res.response.data.message;
              }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            }
          })
          .catch();
      }
    },
    isValidDeviceSn(value) {
      const regex = /^[0-9]{4}-[0-9]{4}-[0-9]{4}$/;
      return regex.test(value) || 'Invalid Device SN';
    },
    async getListAssets(filters) {
      let data = {
        site_id: this.$store.state.siteId ? this.$store.state.siteId : null,
      };
      const params = { ...data, ...filters };
      await assetService.getListAssets(params).then((res) => {
        if (res?.data?.data) {
          if(res.data.data?.record?.length) {
            let uniqueModels = [];
            res.data.data.record.forEach(asset => {
              asset.date_of_commission = this.formatDataTableDate(asset?.date_of_commission) || "−−";
              asset.last_maintenance = this.formatDataTableDate(asset?.last_maintenance) || "−−";
              asset.next_maintenance = this.formatDataTableDate(asset?.next_maintenance) || "−−";
              if (asset.model && !uniqueModels.includes(asset.model)) {
                uniqueModels.push(asset.model);
              }
            });
            this.modelOptions = [...new Set([...this.modelOptions, ...uniqueModels])].sort();
          }
          const data = res.data.data;
          const dataDiagram = res.data.data?.record ? res.data.data.record : [];
          this.dataForm = {...data};
          this.dataAssetsDiagram = [...dataDiagram];
        }
      });
      if(this.isReloadDiagram) {
        this.dataDiagram = this.getDataDiagram();
      }
    },
    deleteAsset(item) {
      if (item?.id) {
        assetService
          .deleteAsset(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.getListAssets();
            this.apiMessage = "Device has been successfully deregistered";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Device cannot be deregister";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async submitAdd() {
      if (this.$refs.addForm.validate()) {
        let siteID = this.$store.state.siteId ? this.$store.state.siteId : null;
        const data = this.prepareData(
          this.deviceName,
          this.deviceType,
          this.deviceBrand,
          this.deviceModel,
          this.deviceSn,
          this.ratedCapacity,
          siteID,
          this.originalSn,
          this.lowerInputVoltageLimit
        );
        await assetService
          .storeAsset(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListAssets();
            this.closeDialogAddAsset();
            this.apiMessage = "Device has been successfully added";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Device cannot be added";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertAsset() {
      this.$refs.addForm.reset();
    },
    openDialogAddAsset() {
      this.deviceType = "",
      this.dialogAddAsset = true;
    },
    openDialogFilterAsset() {
      this.deviceType = "",
      this.dialogFilterAsset = true;
    },
    closeDialogAddAsset() {
      this.setAssetRetrieveInfo(null);
      this.revertAsset();
      this.dialogAddAsset = false;
    },
    resetDialogFilterAsset() {
      this.selectedDeviceTypes = [];
      this.selectedDeviceStatuses = [];
      this.commissionedDateRange = [];
    },
    closeDialogFilterAsset() {
      this.dialogFilterAsset = false;
    },
    openCommissionedDateMenu() {
      return this.commissionedDateMenu = !this.commissionedDateMenu;
    },
    openConfirmDialog(item, action, heading, message) {
      this.assetItem = Object.assign({}, this.assetItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.assetItem = Object.assign({}, this.assetItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    closeMessage() {
      this.messageDialog = false;
    },
    closeCommissionedDateMenu() {
      if(this.commissionedDateRange && this.commissionedDateRange[0] && this.commissionedDateRange[1]){
        this.commissionedDateMenu = false;
      }else{
        this.commissionedDateMenu = true;
      }
      return this.commissionedDateMenu;
    },
    async submitFilter(reload_diagram) {
      this.isReloadDiagram = reload_diagram ? true : false;
      if(!(this.selectedDeviceTypes?.length || this.selectedDeviceStatuses?.length || this.commissionedDateRange?.length)) {
        await this.getListAssets();
      } else {
        let filters = {};
        if(this.selectedDeviceTypes?.length) {
          filters.device_type = JSON.stringify(this.selectedDeviceTypes);
        }
        if(this.selectedDeviceStatuses?.length) {
          filters.status = JSON.stringify(this.selectedDeviceStatuses);
        }
        if(this.commissionedDateRange?.length === 2) {
          filters.start_date = this.commissionedDateRange[0];
          filters.end_date = this.commissionedDateRange[1];
        }
        await this.getListAssets(filters);
      }
      if (!reload_diagram) {
        this.closeDialogFilterAsset();
      }
    },
    setAssetInfo(data) {
      if (data) {
        this.editDeviceName = data.name || "";
        this.editDeviceSn = data.sn || "";
        this.editOriginalSn = data.original_sn || "";
        this.editDeviceType = data.type || "";
        this.editDeviceBrand = data.brand || "";
        this.editDeviceModel = data.model || "";
        this.editRatedCapacity = data.rated_capacity || "";
        this.assetInfo = data;
        this.editLowerInputVoltageLimit = data.pv_lower_limit || "";
        this.deviceNameText = data.type + " \\ " +data.brand+ " \\ " + data.model;
      } else {
        this.editDeviceName = "";
        this.editDeviceSn = "";
        this.editOriginalSn = "";
        this.editDeviceType = "";
        this.editDeviceBrand = "";
        this.editDeviceModel = "";
        this.editRatedCapacity = "";
        this.assetInfo = null;
        this.editLowerInputVoltageLimit = "";
        this.deviceNameText = "";
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        assetService
          .getAsset(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setAssetInfo(res.data.data);
              this.dialogEditAsset = true;
              this.activeSection = "info";
            }
          })
          .catch();
      }
    },
    closeDialogEditAsset() {
      this.setAssetInfo(null);
      this.revertAssetEdit();
      this.dialogEditAsset = false;
    },
    revertAssetEdit() {
      this.$refs.editForm.reset();
    },
    prepareData(device_name, device_type, device_brand, device_model, device_sn, rated_capacity, site_id, original_sn, pv_lower_limit) {
      const data = {
        name: device_name,
      };
      if (device_type) {
        data.type = device_type;
      }
      if (device_brand) {
        data.brand = device_brand;
      }
      if (device_model) {
        data.model = device_model;
      }
      if (device_sn) {
        data.sn = device_sn;
      }
      if (rated_capacity) {
        data.rated_capacity = rated_capacity;
      }
      if (site_id) {
        data.site_id = site_id;
      }
      if (original_sn) {
        data.original_sn = original_sn;
      }
      if (pv_lower_limit) {
        data.pv_lower_limit = pv_lower_limit;
      }
      return data;
    },
    submitEdit() {
      if (this.$refs.editForm.validate()) {
        const data = this.prepareData(
          this.editDeviceName,
          null,
          this.editDeviceBrand,
          this.editDeviceModel,
          null,
          this.editRatedCapacity,
          null,
          this.editOriginalSn,
          this.editLowerInputVoltageLimit
        );

        assetService
          .updateAsset(this.assetInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = "Device's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListAssets();
            this.closeDialogEditAsset();
          })
          .catch((err) => {
            let message = "Device's information cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    showDeviceKey(device_key) {
      const maxLength = 15;
      const deviceKey = device_key;
      return deviceKey.length > maxLength ? deviceKey.slice(0, maxLength) + '...' : deviceKey;
    },
    copyDeviceKey(device_key) {
      const input = document.createElement('input');
      input.value = device_key;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
    async getListAssignInstallers() {
      return await siteService
        .getListAssignInstallers()
        .then((res) => {
          if (res?.data?.data) {
            const dataAssignInstallers = res.data.data;
            this.assignInstallersList = [...dataAssignInstallers];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    openDialogMaintenanceSchedule() {
      this.dialogMaintenanceSchedule = true;
    },
    changedDate() {
      return this.nextMaintenanceDateText = moment(this.nextMaintenanceDate).format("DD/MM/YYYY");
    },
    revertMaintenanceSchedule() {
      this.$refs.maintenanceScheduleForm.reset();
    },
    closeDialogMaintenanceSchedule() {
      this.revertMaintenanceSchedule();
      this.nextMaintenanceDate = "";
      this.dialogMaintenanceSchedule = false;
    },
    prepareDataMaintenance(site_id, date, installer) {
      const data = {
        site_id: site_id,
        maintenance_date: date,
        assign_installer: installer,
      };
      return data;
    },
    async submitMaintenanceSchedule() {
      if (this.$refs.maintenanceScheduleForm.validate()) {
        let siteID = this.$store.getters.siteId ? this.$store.getters.siteId : null;
        let maintenance_date = moment.utc(this.nextMaintenanceDate).toISOString();
        const data = this.prepareDataMaintenance(
          siteID,
          maintenance_date,
          this.assignInstaller,
        );
        await assetService
          .storeMaintenanceSchedule(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListAssets();
            this.closeDialogMaintenanceSchedule();
            this.apiMessage = "Maintenance schedule has been successfully created";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Maintenance schedule cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    searchInstallers() {
      if (!this.searchTerm) {
        this.assignInstallersList = this.installersListCopy;
      } else {
        this.assignInstallersList = this.installersListCopy.filter((installer) => {
          if (installer.username && typeof installer.username === 'string') {
            return installer.username.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
          } else {
            return false;
          }
        });
      }
    },
    openDialogDeviceDetail (dataDevice) {
      if (dataDevice) {
        this.data_device = dataDevice;
        this.is_device_detail = true;
      }
    },
    closeDialogDeviceDetail () {
      this.is_device_detail = false;
    },
    notAllowedActions () {
      return (!this.accessibleRoutes?.asset?.action || ['home_user'].includes(this.user?.user_group?.type));
    },
    handleInputModel (model) {
      if (model) {
        this.showAutocomplete = true;
      } else {
        this.showAutocomplete = false;
      }
    },
    selectModelOption (option, type) {
      if(type === 'add') {
        this.deviceModel = option;
      } else {
        this.editDeviceModel = option;
      }
      this.showAutocomplete = false;
    },
    closeAutocomplete () {
      setTimeout(() => {
        this.showAutocomplete = false;
      }, 200);
    },
    navigateOptions (event, type) {
      if(type === 'add') {
        if (event.keyCode === 38) {
          event.preventDefault();
          if (this.highlightedIndex === 0) {
            this.highlightedIndex = this.filteredOptions.length - 1;
          } else {
            this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
          }
        } else if (event.keyCode === 40) {
          event.preventDefault();
          if (this.highlightedIndex === this.filteredOptions.length - 1) {
            this.highlightedIndex = 0;
          } else {
            this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.filteredOptions.length - 1);
          }
        }
      } else {
        if (event.keyCode === 38) {
          event.preventDefault();
          if (this.highlightedIndex === 0) {
            this.highlightedIndex = this.filteredOptionsEdit.length - 1;
          } else {
            this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
          }
        } else if (event.keyCode === 40) {
          event.preventDefault();
          if (this.highlightedIndex === this.filteredOptionsEdit.length - 1) {
            this.highlightedIndex = 0;
          } else {
            this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.filteredOptionsEdit.length - 1);
          }
        }
      }
    },
    selectHighlightedOption (type) {
      if(type === 'add') {
        if (this.highlightedIndex !== -1) {
          this.selectModelOption(this.filteredOptions[this.highlightedIndex]);
        }
      } else {
        if (this.highlightedIndex !== -1) {
          this.selectModelOption(this.filteredOptionsEdit[this.highlightedIndex]);
        }
      }
    },
  },
  beforeDestroy() {
    if (this.socketDevice) {
      this.socketDevice.close();
    }
  },
};
</script>
